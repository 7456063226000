const {
    throttledExecute: throttledExecuteAdd,
    throttleResets: throttleResetsAdd,
    rebaseResets: rebaseResetsAdd,
} = useThrottledExecute();

/*
    Lint disable here is needed because we have to
    use named export for hot reload to work
*/
// eslint-disable-next-line import/prefer-default-export
export const useCartStore = defineStore('CartStore', {
    /*
        NOTE: We use a virtual cart on frontend side to be visually performant
        and can delay synchronization with the backend to prevent overloading
        the server with requests
    */
    state: () => ({
        /* General */
        _strapiClient: null,
        isInitialized: false,

        /* Amount */
        currency: 'CHF',
        totalAmount: 0,

        /* User uuid */
        userHash: '',
        userQuery: '',

        /* The items grouped into cart entries */
        vCart: [],

    }),

    actions: {
        /*
            Sync discount from backend with vCart
        */
        async _syncDiscountWithBackend(response) {
            this.vCart = response.cart_entries;
            this.totalAmount = response.total_amount;
        },
        /*
            Add a discount to cart
        */
        async addDiscountToCart(discountCode) {
            const response = await this._strapiClient(`/carts/apply-discount?${this.userQuery}`, {
                method: 'POST',
                body: {
                    discountCode,
                },
            });

            if (response?.error) {
                throw createError({
                    statusCode: e.status,
                    statusMessage: e.response.data.error,
                });
            }

            this._syncDiscountWithBackend(response);
        },

        async removeDiscountFromCart() {
            const response = await this._strapiClient(`carts/remove-discount?${this.userQuery}`, {
                method: 'POST',
            });

            this._syncDiscountWithBackend(response);

            if (response?.error) {
                throw createError({
                    statusCode: e.status,
                    statusMessage: e.response.data.error,
                });
            }

            const checkoutStore = useCheckoutStore();
            checkoutStore.removeDiscount();

            return response;
        },

        /*
            Fetch users cart
        */
        fetchUserCart() {
            return this._strapiClient(`/carts/my-cart?${this.userQuery}`, {
                method: 'GET',
            });
        },

        /*
            Revoke cart (needed after logout to make sure no data is remaining)
        */
        revokeCart() {
            deleteLocalStorageItem('u-hash');

            this.vCart.length = 0;
            this.totalAmount = 0;
            this.userHash = getUid();
            setLocalStorageItem('u-hash', this.userHash);
            this.userQuery = useQueryString({
                userHash: this.userHash,
            });
            this.initializeCart();
        },

        /*
            Clear the whole cart
        */
        _apiClearCart() {
            return this._strapiClient(`/carts/remove-all?${this.userQuery}`, {
                method: 'POST',
            });
        },

        _apiGetLastOrder() {
            return this._strapiClient(`/orders/last-order?${this.userQuery}`, {
                method: 'GET',
            });
        },

        async clearCart(useAlertBox = true) {
            const alertBoxStore = useAlertBoxStore();
            let alertBoxType = '';

            const vCartCopy = [...this.vCart];
            this.vCart.length = 0;

            try {
                await this._apiClearCart();
                alertBoxType = 'success';
            } catch (e) {
                useSentryError(e);
                alertBoxType = 'error';

                /*
                    If clearing fails we add the products
                    again to vCart to be sync with backend
                */
                this.vCart = vCartCopy;
            } finally {
                if (useAlertBox) {
                    const alertBoxTexts = {
                        error_text: useTranslation('general', 'cart_clear_error'),
                        success_text: useTranslation('general', 'cart_clear_success'),
                    };

                    alertBoxStore.setAlertBoxData(alertBoxType, alertBoxTexts);
                    alertBoxStore.toggleAlertbox();
                }
            }
        },

        async getLastOrder() {
            try {
                return this._apiGetLastOrder();
            } catch (e) {
                useSentryError(e);
                return null;
            }
        },

        /*
            handling cart allocation (real cart and hash cart)
            If a user enters page a new hash will generated and created a
            real cart bound to the hash in backedn. After login hash Cart will be
            removed and merged into the user bound cart
        */
        initStrapiClient() {
            this._strapiClient = useStrapiClient();
            /* User identification by uuid hash for guest carts  */
            const storedHash = getLocalStorageItem('u-hash');
            this.userHash = storedHash || getUid();

            /*
                For simplicity userhash will always be sent backend handles choosing
                the right cart (hashCart or userCart)
                Thats why we build the querystring already here and store it
            */
            this.userQuery = useQueryString({
                userHash: this.userHash,
            });
        },

        async initializeCart() {
            if (this.isInitialized) return;

            this.vCart.length = 0;

            /* Check if uuid is valid */
            if (!isValidUid(this.userHash)) {
                useSentryError({
                    statusCode: '500',
                    statusMessage: 'Fatal: Invalid uuid passed to @useCartStore',
                });

                return;
            }

            /* Store userhash to localstorage */
            setLocalStorageItem('u-hash', this.userHash);

            try {
                const fetchedCart = await this.fetchUserCart();
                if (!fetchedCart) return;
                this.totalAmount = fetchedCart.total_amount || 0;

                fetchedCart.cart_entries.forEach((entry) => {
                    this.vCart.push(entry);
                });

                this.isInitialized = true;
            } catch (e) {
                useSentryError(e);
            }
        },

        /*
            Handling adding and removing products
        */
        async _apiRemoveProduct(item) {
            const productId = item?.shopItemId;
            if (!productId) {
                useSentryError({
                    statusCode: '500',
                    statusMessage: 'Fatal: No product id found @useCartStore - _apiRemoveProduct',
                });
            }

            return this._strapiClient(`/carts/remove-product?${this.userQuery}`, {
                method: 'POST',
                body: {
                    productId,
                },
            });
        },

        removeProduct(item) {
            const entryIndex = this._getCartEntryIndex(item);
            this.vCart.splice(entryIndex, 1);
            this.setTotalAmount();
            return this._apiRemoveProduct(item);
        },

        async _apiAddProduct(item) {
            const quantity = this.getProductQuantity(item);

            /* If quantity is zero we remove the product instead of adding it */
            if (quantity === 0) {
                return this.removeProduct(item);
            }

            /* For security reason we prevent sending addProduct without quantity */
            if (!quantity) return false;

            const { getClientID } = useGTM();
            const clientId = getClientID();

            return this._strapiClient(`/carts/add-product?${this.userQuery}`, {
                method: 'POST',
                body: {
                    productId: item.shopItemId,
                    quantity,
                    clientId,
                },
            });
        },

        addProduct(item, options) {
            const cartPopupStore = useCartPopupStore();
            const alertBoxStore = useAlertBoxStore();

            const defaultOptions = {
                openPopup: true,
                isAdd: true,
                openAlertSuccess: true,
            };
            const settings = { ...defaultOptions, ...options };

            const entryIndexInVCart = this._getCartEntryIndex(item);

            let entry = null;
            if (entryIndexInVCart !== -1) {
                /* If a entry already exists we just update the quantity of it */
                entry = this.vCart[entryIndexInVCart];
                entry.quantity = settings.isAdd
                    ? entry.quantity += 1
                    : entry.quantity - 1;

                /* here we temporarly precalculate the price frontend side */
                entry.amount_total = round(entry.quantity * item.price);
            } else {
                /* If a entry does not exists we create a new one with quantiy 1 */
                entry = {
                    quantity: 1,
                    amount_total: item.price,
                    product: item,
                };
                this.vCart.push(entry);
            }

            /*
                If openPopup is true we add it to the popup
                otherwise we will inform user later with alertbox
            */
            if (settings.openPopup) {
                cartPopupStore.addProduct(item);
            }

            this.setTotalAmount();

            if (settings.isAdd) {
                const { sendEvent } = useGTM();

                sendEvent({
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: 'CHF',
                        value: null,
                        items: [
                            {
                                item_id: entry?.product?.shopItemId,
                                item_name: entry?.product?.name,
                                item_category: entry?.product?.product_categories?.map((cat) => cat?.name).join(', '),
                                item_brand: entry?.product?.brand?.name,
                                price: entry?.product?.price,
                                quantity: entry?.quantity,
                            },
                        ],
                    },
                    value: this.totalAmount,
                });
            }

            /* Throttle the api calls */
            throttledExecuteAdd(async () => {
                let alertBoxType = 'error';

                try {
                    const response = await this._apiAddProduct(item, settings.isAdd);
                    this._syncWithResponse(response);
                    alertBoxType = 'success';
                } catch (e) {
                    alertBoxType = 'error';
                    /*
                        if something went wrong we want to reset
                        the quantity to ensure still matching backend cart
                    */
                    this._handleErrorResets(item, settings.isAdd);
                    useSentryError(e);
                } finally {
                    /* If no popup opens we use alert box to inform the user */
                    if (!settings.openPopup && !alertBoxStore.isOpen && settings.isAdd) {
                        const alertBoxTexts = {
                            error_text: useTranslation('general', 'cart_add_error'),
                            success_text: useTranslation('general', 'added_to_cart'),
                        };

                        alertBoxStore.setAlertBoxData(alertBoxType, alertBoxTexts);

                        if (alertBoxType === 'success' && settings.openAlertSuccess) {
                            alertBoxStore.toggleAlertbox();
                        }

                        if (alertBoxStore === 'error') {
                            alertBoxStore.toggleAlertbox();
                        }
                    }
                }
            });
        },

        /*
            If an error occures while adding products to backend
            we handle the item amount with this function to ensure
            staying synced with backend
        */
        _handleErrorResets(item, isAdd = true) {
            const resetQuantity = throttleResetsAdd.value;
            const entryIndex = this._getCartEntryIndex(item);
            const entry = this.vCart[entryIndex];

            entry.quantity = isAdd
                ? entry.quantity - resetQuantity
                : entry.quantity + resetQuantity;

            /* Set throttle reset counter back to 0 */
            rebaseResetsAdd();
        },

        setTotalAmount() {
            const hasDiscount = this.vCart.some((entry) => entry.is_discount);

            const filteredEntries = this.vCart.filter((entry) => !entry.is_discount);
            let totalAmount = filteredEntries.reduce((acc, entry) => acc + entry.amount_total, 0);

            if (hasDiscount) {
                const discountEntry = this.vCart.find((entry) => entry.is_discount);

                switch (discountEntry.discount.type) {
                case 'Percentage':
                    totalAmount -= (totalAmount * (discountEntry.discount.discount / 100));
                    break;
                case 'Fixed Amount':
                    totalAmount -= discountEntry.discount.discount;
                    break;
                default: break;
                }
            }

            this.totalAmount = totalAmount;
        },

        /*
            after backend sends back response we sync
            prices to ensure backend prices are source of truth
        */
        _syncWithResponse(response) {
            this.totalAmount = response.total_amount;
            const productEntries = response.cart_entries?.filter((entry) => !entry.is_discount);
            productEntries?.forEach((entry) => {
                const entryIndex = this._getCartEntryIndex(entry.product);
                const vCartEntry = this.vCart[entryIndex];
                vCartEntry.amount_total = entry.amount_total;
                vCartEntry.id = entry.id;
                vCartEntry.quantity = entry.quantity;
                vCartEntry.product = entry.product;
                /*
                    We also sync price of product in case
                    of the price of the product changed meanwhile
                */
                vCartEntry.price = entry.product?.price;
            });
        },

    },

    getters: {
        /*
            Check if cart has at least one product
        */
        getHasProducts(state) {
            const filteredItems = state.vCart?.filter((entry) => !entry.is_discount);
            return filteredItems?.length > 0;
        },
        /*
            Get user hash
        */
        getUserHash(state) { return state.userHash; },

        /*
            Get if cart has a discount
        */
        getHasDiscount(state) {
            return state.vCart?.some((entry) => entry?.is_discount);
        },

        /*
            Get quantity of a given product from virtual cart
        */
        getProductQuantity: (state) => (searchObj) => {
            const filteredEntries = state.vCart?.filter((entry) => !entry.is_discount);
            return filteredEntries?.find(
                (entry) => entry.product.id === searchObj.id,
            )?.quantity || 0;
        },

        /*
            Helper to check if a product already exists in a entry
            @return Integer (index of the entry) or -1 if not found
        */
        _getCartEntryIndex: (state) => (item) => state.vCart.findIndex(
            (entry) => entry.product?.shopItemId === item.shopItemId,
        ),

        /*
            Get the amount of entries in the cart (products are grouped into entries)
        */
        getAmountOfCartEntries(state) { return state.vCart.length; },
        /*
            Get Summary of a entry
        */
        getEntrySummary: (state) => (item) => {
            const entryIndex = state.vCart.findIndex(
                (entry) => entry.product?.shopItemId === item.shopItemId,
            );

            if (entryIndex === -1) return 0;

            const vCartEntry = state.vCart[entryIndex];
            return vCartEntry.amount_total;
        },

        /*
            Get the cart
        */
        getCart(state) {
            if (this.getHasDiscount) {
                const checkoutStore = useCheckoutStore();
                const discountEntry = state.vCart.find((entry) => entry.is_discount);
                if (discountEntry) {
                    checkoutStore.setDiscount(discountEntry.discount.code);
                }
            }
            return state.vCart;
        },

        isEmpty(state) { return state.vCart.length === 0; },

        getCurrency(state) { return state.currency; },

        getTotalAmount(state) {
            return state.totalAmount;
        },

        isClickAndCollect(state) {
            return state.vCart.findIndex((entry) => entry.product?.isSendable === false) !== -1;
        },

        hasClickAndCollect(state) {
            return state.vCart.findIndex((entry) => entry.product?.isSendable === false) !== -1;
        },

        isFreeShipping(state) {
            const discountEntry = state.vCart.find((entry) => entry.is_discount);
            if (!discountEntry) return false;
            return discountEntry.discount.type === 'Free Shipping';
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
