export default defineStore('CartPopup', {
    state: () => ({
        isOpen: false,
        product: {},
    }),

    actions: {
        addProduct(product) {
            this.product = product;
            this.isOpen = true;
        },

        openPopup() {
            this.isOpen = true;
        },

        closePopup() {
            this.isOpen = false;
        },
    },

    getters: {
        getRenderState(state) {
            return state.isOpen;
        },

        getProduct(state) {
            return state.product;
        },
    },
});
