// eslint-disable-next-line import/prefer-default-export
export const useCheckoutStore = defineStore('CheckoutStore', {
    state: () => ({
        /* Shipping */
        shippingMethod: null,
        clickAndCollectStore: null,

        /* Billing */
        billingAddress: {},

        /* Delivery */
        deliveryAddress: {},

        /* Request body */
        requestBody: {
            successUrl: null,
            failedUrl: null,
            cancelUrl: null,
            storeId: 14,
            couponCode: null,
            shippingOption: null,
            isClickAndCollect: false,

            details: {
                billingAddress: null,
                deliveryAddress: null,
            },
        },
    }),

    actions: {
        setDiscount(discount) {
            this.requestBody.couponCode = discount;
        },

        removeDiscount() {
            this.requestBody.couponCode = null;
        },

        _mapAddress(addressArray) {
            if (addressArray.length > 0) {
                const address = addressArray[0];
                delete address.createdAt;
                delete address.id;
                delete address.updatedAt;

                if (address.address_type === 'account') {
                    this.requestBody.details.deliveryAddress = {
                        salutation: address.salutation,
                        firstname: address.prename,
                        lastname: address.name,
                        street: address.street,
                        street_number: address.number,
                        postcode: address.postcode,
                        city: address.city,
                        email: address.email,
                        phone: address.phone,
                        country: 'Schweiz',
                    };
                } else {
                    this.requestBody.details.billingAddress = {
                        salutation: address.salutation,
                        firstname: address.prename,
                        lastname: address.name,
                        street: address.street,
                        street_number: address.number,
                        postcode: address.postcode,
                        city: address.city,
                        email: address.email,
                        phone: address.phone,
                        country: 'Schweiz',
                    };
                }

                const addressState = address.address_type === 'account'
                    ? this.deliveryAddress
                    : this.billingAddress;

                Object.entries(address).forEach(([key, value]) => {
                    addressState[key] = value;
                });
            }
        },

        deleteAddress(type) {
            switch (type) {
            case 'billing':
                this.billingAddress = {};
                this.requestBody.details.billingAddress = null;
                break;
            case 'delivery':
                this.deliveryAddress = {};
                this.requestBody.details.deliveryAddress = null;
                break;
            default:
                this.billingAddress = {};
                this.deliveryAddress = {};

                this.requestBody.details.billingAddress = null;
                this.requestBody.details.deliveryAddress = null;
            }
        },

        setUrls() {
            const successLink = `${getStoryblokSetting('general', 'payment_success_link')?.cached_url}?clearCart=true`;

            const runtimeConfig = useRuntimeConfig();
            const { BASE_URL } = runtimeConfig.public;

            const failedBaseLink = getStoryblokSetting('general', 'payment_failed_link')?.cached_url;
            const failedLink = `${failedBaseLink.slice(0, -1)}?aborted=failed`;
            const canceledLink = `${failedBaseLink.slice(0, -1)}?aborted=canceled`;

            this.requestBody.successUrl = `${BASE_URL}/${successLink}`;
            this.requestBody.failedUrl = `${BASE_URL}/${failedLink}`;
            this.requestBody.cancelUrl = `${BASE_URL}/${canceledLink}`;
        },

        setAddress(data, type) {
            if (!data) return;
            const addressData = data;
            delete addressData.statusData;

            /* Map the correct address type to macht backend structure */
            addressData.address_type = type === 'delivery' ? 'account' : 'billing';
            this._mapAddress([addressData]);
        },

        setClickAndCollectStore(store) {
            this.clickAndCollectStore = store;
            this.requestBody.storeId = store;
        },

        setShippingOption(option) {
            if (option.isClickAndCollect) {
                this.requestBody.shippingOption = null;
            } else {
                this.requestBody.shippingOption = option.id;
            }

            this.requestBody.isClickAndCollect = option.isClickAndCollect;
            this.shippingMethod = option;
        },

        async placeOrder() {
            try {
                const cartStore = useCartStore();
                const userHash = cartStore.getUserHash;
                const strapiClient = useStrapiClient();
                const { getClientID } = useGTM();
                const clientId = getClientID();
                this.requestBody.clientId = clientId;

                const response = await strapiClient(`carts/convert-to-order?userHash=${userHash}`, {
                    method: 'POST',
                    body: this.requestBody,
                });

                window.location.href = response.payment_url;
            } catch (e) {
                /*
                    Note for now we leave error message hardcoded.
                    Will implement that in the afternoon
                */
                const alertBoxStore = useAlertBoxStore();
                alertBoxStore.setAlertBoxData(
                    'error',
                    {
                        error_text: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
                    },
                );
                alertBoxStore.toggleAlertbox();
                useSentryError(e);
            }
        },
    },
    getters: {
        getAdress() {
            return {
                deliveryAddress: this.deliveryAddress,
                billingAddress: this.billingAddress,
            };
        },
        getShippingMethod() {
            return this.shippingMethod;
        },

        getShippingCosts() {
            if (!this.shippingMethod) return 0;

            const cartStore = useCartStore();
            const totalAmount = cartStore.getTotalAmount;
            const { shipping_free_from: freeFrom, fee } = this.shippingMethod;

            if (cartStore.isFreeShipping) return 0;

            if (totalAmount - fee < freeFrom) {
                return fee;
            }

            return 0;
        },

        getFormattedShippingCosts() {
            if (!this.shippingMethod) return 0;

            const cartStore = useCartStore();
            const totalAmount = cartStore.getTotalAmount;
            const { shipping_free_from: freeFrom, fee } = this.shippingMethod;

            if (cartStore.isFreeShipping) {
                return {
                    value: `${cartStore.getCurrency} 0.00`,
                };
            }

            if (totalAmount - fee < freeFrom) {
                return {
                    value: `${cartStore.getCurrency} ${round(fee).toFixed(2)}`,
                };
            }

            return {
                value: `${cartStore.getCurrency} 0.00`,
                additionalText: `${useTranslation('general', 'free_shipping_from')} ${cartStore.getCurrency} ${round(freeFrom).toFixed(2)}`,
            };
        },

        getActivePharmacy() {
            return this.requestBody.storeId;
        },

        getRequestBody() {
            return this.requestBody;
        },

        getClickAndCollectStore() {
            return this.clickAndCollectStore;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCheckoutStore, import.meta.hot));
}
